.apptistcontainer {
    width: 100%;
    padding-right: var(--bs-gutter-x,.75rem);
    padding-left: var(--bs-gutter-x,.75rem);
    margin-right: auto;
    margin-left: auto;
}
.banner1 {
    background-image: url("../../Utils/Group 11061@2x.png");
    margin: 0px 0px 0px 0px;
    height: 618px;
    width: 100%;
    background-position: top center;
    background-size: cover;
    align-items: center;
    display: flex;
    justify-content: center;
    color: white;
}

.heads {
    display: flex;
    align-items: center;

}

.texta {
    text-align: left;
    margin-left: 20px;

}

.line {
    border-left: 2px solid black;
    height: 75px;
    margin-left: 62px;

}

.linecont {
    margin: 60px 220px 60px 220px;
    /* border: 1px solid; */
    padding: 10px;
    box-shadow: 0px 6px 26px -11px #888888;

}
.texth {
    font-size: 36px;
    font-weight: 500;

}
.textp {
    font-size: 22px;
    font-weight: 500;

}
.course{

    margin: 100px 0px 90px 0px;

}
.courseh{
    margin: 0px 0px 12px 0px;
    font-size: 70px;
    font-weight: 600;
    text-align: center;
}
.coursep{
    margin: 0px;
    font-size: 21px;
    font-weight: 500;
    text-align: center;
}

@media (max-width:576px){
    .courseh {
        margin: 0% 0% 7% 0%;
        font-size: 52px;
        font-weight: 600;
    text-align: center;

    }
    .coursep {
        margin: 0% 10%;
        font-size: 18px;
        font-weight: 500;
    text-align: center;

    }
}