.imgbanner {
    width: 100%
}

.banner123 {
    background-image: url("../../Utils/Group 11082@2x.png");
    margin: 0px 0px 0px 0px;
    height: 618px;
    width: 100%;
    background-position: top center;
    background-size: cover;
    align-items: center;
    display: flex;
    justify-content: center;
    color: white;
}

.bannerimgs123 h1 {
    font-size: 77px;
    font-weight: 700;
}

.bannerimgs123 p {

    font-size: 24px;
    font-weight: 500;
    margin: 0% 15%;

}

.containercart {
    margin: 5% 5% 5% 5%;
    position: relative;
}

/* teams card css */

.team123 {
    margin: 0px;
    background-image: url("../../Utils/person (4).jpg");
    background-position: top center;
    background-size: cover;
    border: 2px solid rgb(255, 255, 255);
    border-radius: 25px;
    max-width: 365px;
}

.team123h {
    margin: 364px 0px 0px 10px;
    color: white;
    font-size: 30px;
    font-weight: bold;
    text-align: left;


}

.team123p {
    margin: 10px;
    font-size: 20px;
    color: white;
    text-align: left;

}

.linelogos123 {
    display: flex;
    color: white;
    align-items: center;
    margin: 13px 10px 13px 10px;
}

.billingform {
    margin-top: 7%;
}

.formfield {

    height: 64px;
    border: 1px solid #707070;
    border-radius: 0px;

}

.formoptionalfield {
    height: 64px;
    width: 80%;
    margin: 0% 5%;
    border: 1px solid #707070;
    border-radius: 0px;
}


.clicklogin {
    font-size: 24px;
    /* box-shadow: rgb(246 246 246) 7px 11px 6px -6px; */
    text-align: start;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 1% 0% 1% 2%;
}

.bilingdetails {
    font-size: 45px;
    text-align: left;
    margin-bottom: 7%;
}

.additionalinfo {
    font-size: 45px;
    margin-bottom: 7%;
}
.selectPayH {
    text-align: left;
    font-size: 45px;
    margin: 3% 0%;
    font-weight: 600;
}
.payment {
    justify-content: left;
    display: flex;
    align-items: center;
    margin-left: 1%;
}
.imgpayment {
    height: 35px;
    /* width: 113px; */
}
.parapaymentdiv {
    margin: 5% 14% 5% 8%;
    text-align: start;
    font-size: 22px;
}
.tablestyle23but {
    padding: 1% 6%;
    font-size: 25px;
    margin: 3% 0% 0% 0%;
    float: right;
}

/* table css */

/* .tablestyle {
    border: 1px solid #707070;
    text-align: left;
}
.tablestyleall {
    font-size: 25px;
}
.tablestyleheadp {
    margin-left: 8%;
    font-size: 25px;
} */

/* right side part */
.weekendHeading {
    font-size: 50px;
    margin-bottom: 6%;

}
.tablecontent {
    font-size: 28px;
}
.tablecontentbut {
    font-size: 22px;
    padding: 2% 18%;
}
.tableweekend {
    margin: 0% 8% 0% 14%;
}


    /* .tablestyle123 {

        margin-left: 0%;

    } */

.tablestyle123 {
    font-size: 45px;
    text-align: left;
    margin: 8% 0%;

}

.tablestyle23 {
    border: 1px solid #707070;
    text-align: left;
    /* margin-left: 50%; */

    max-width: 85%;
}
.tablecontentbut {
    font-size: 22px;
    padding: 2% 18%;
}

@media (max-width:1400px) {
    .bannerimgs123 h1 {
        font-size: 50px;
        font-weight: 600;
    }
    .bannerimgs123 p {
        font-size: 23px;
        font-weight: 500;
        margin: 0% 15%;
    }
}
@media (max-width:900px) {
    .bannerimgs123 h1 {
        font-size:40px;
        font-weight: 600;
    }
    .bannerimgs123 p {
        font-size: 22px;
        font-weight: 500;
        margin: 0% 15%;
    }
}

@media (max-width:640px) {
    .bannerimgs123 h1 {
        font-size: 32px;
        font-weight: 600;
    }
    .bannerimgs123 p {
        font-size: 24px;
        font-weight: 500;
        margin: 0% 15%;
    }
}


@media (max-width:400px) {
    .bannerimgs123 h1 {
        font-size: 20px;
        font-weight: 600;
    }
    .bannerimgs123 p {
        font-size: 16px;
        font-weight: 500;
        margin: 0% 15%;
    }
}

