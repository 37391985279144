.cardb {
    margin: -7px 0px 0px 0px;
    background-color: #FFE399;
    height: 963px;
    display: flex;
    align-items: center;
}

.cardaa {
    margin: 0px;
}

.cardhh {
    margin: 0% 0% 3% 6%;
    text-align: left;
    font-size: 104px;
    font-weight: 900;
}

.cardpp {
    margin: 0% 14% 3% 6%;
    text-align: left;
    font-size: 24px;
    font-weight: 500;
}

.testtest-newnew {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 636px;
    z-index: -1;
}

.last12 {
    display: flex;
    align-items: center;
    justify-content: left;
    margin-left: 6%;

}

.icon123 {

    color: black;
    border: 2px solid #ffffff;
    padding: 6px;
    margin-right: 19px;
    border-radius: 50%;
    font-size: 35px;
    background-color: #ffffff;
    z-index: 999;


}

.lorem {
    margin: 5% 15% 5% 15%;
}

.lorem h1 {
    font-size: 58px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 3%;
}

.lorem p {
    font-size: 21px;
    text-align: center;
    
}

.series {
    margin: -2% 10% 4% 10%;
    text-align: left;
}

.display {
    display: flex;
}

.liness {
    border-left: 2px solid black;
}

.hellos {
    margin: 0% -5% 0% 9%;
}

.helloo {
    margin: 0% 0% 0% 15%;
}

.aboutus {
    margin: 0% 20% 0% 10%;
    text-align: left;
}

.aboutus h1 {
    font-size: 58px;
}

.aboutus p {
    font-size: 21px;
}
.imgaboutus{
    margin:0% 0% 0% 3.2%;
}

.companyname {
    font-size: 10px;
}

.cardtext {
    text-align: left;
    margin: 19% 10% 0% 10%;
}

.cardtext h5 {
    margin-bottom: 2%;
    font-size: 45px;
    color: black;
}

.cardtext p {
    margin: 3% 0% 0% 0%;
    font-weight: 500;
    font-size: 23px;
    color: black;
}

@media (max-width:1400px) {

    .cardtext {
        text-align: left;
        margin: 10% 24% 0% 10%;
    }

}

@media (max-width:1200px) {
    .cardtext h5 {
        margin-bottom: 2%;
        font-size: 36px;
        color: black;
    }

    .cardtext {
        text-align: left;
        margin: 10% 24% 7% 10%;
    }


}


@media (max-width:992px) {
    .cardhh {
        margin: 0% 0% 3% 6%;
        text-align: left;
        font-size: 74px;
    }

    .cardpp {
        margin: 0% 14% 3% 6%;
        text-align: left;
        font-size: 21px;
        font-weight: 500;
    }

    .lorem h1 {
        font-size: 50px;
        font-weight: 500;
    }

    .lorem p {
        font-size: 18px;
    }

    .aboutus h1 {
        font-size: 50px;
    }

    .aboutus p {
        font-size: 18px;
    }

    .cardtext h5 {
        margin-bottom: 2%;
        font-size: 28px;
        color: black;
    }

    .cardtext p {
        margin: 3% 0% 0% 0%;
        font-weight: 500;
        font-size: 17px;
        color: black;
    }

    .cardtext h5 {
        margin-bottom: 7%;
        font-size: 21px;
        color: black;
        font-weight: 800;
    }

}

@media (max-width:576px) {
    .hellos {
        margin: 5% 0% 0% 0%;
    }

    .helloo {
        margin: 3% 0% 0% 4%;
    }

   

    .testtest-newnew {
        position: absolute;
        top: 0;
        left: 0;
        max-width: 0;
        z-index: -1;
    }

    .liness {
        border-left: 0px solid black;
    }

    .cardb {
        margin: -7px 0px 0px 0px;
        background-color: #FFE399;
        height: 640px;
        display: flex;
        align-items: center;
    }

    .cardpp {
        margin: 0% 14% 3% 6%;
        text-align: left;
        font-size: 18px;
        font-weight: 500;
    }
    .cardtext {
        text-align: left;
        margin: 10% 24% 10% 10%;
    }
}