.paystyle {
    position: fixed;
    top: 0;
    z-index: 999;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    /* max-width: 500px; */
}

.imgpaycongo {
    max-width: 700px;
}

@media (max-width:767px) {
    .imgpaycongo {
        max-width: 500px;
    }
}
@media (max-width:600px) {
    .imgpaycongo {
        max-width: 350px;
    }
}