.style {
    margin: 0%;
}

.imgstyle {
    height: 1080px;
}

.formcontainer {
    padding: 2% 10% 0% 11%;
}
.toas{
    margin-top: 4%;
    color: rgb(250, 218, 74);
    /* border: 1px solid;
    padding: 10px;
    box-shadow: 5px 10px 8px 10px #888888; */
}

.Logincss h1 {
    font-size: 58px;
    color: black;
    font-weight: 500;

}

.Logincss:hover {
    font-size: 58px;
    color: black;
}

.Loginline {
    border-bottom: 6px solid #707070;
    width: 188px;
    /* margin: auto; */
}

.Registercss {
    /* font-size: 43px; */
    color: black;
    /* font-weight: 400; */

}

.Registercss h1 {
    font-size: 58px;
    color: black;
    font-weight: 400;

}

.Registercss:hover {
    font-size: 58px;
    color: black;
    /* border-bottom: 4px solid black; */
    /* width: 124px; */
    /* margin: auto; */
}

/* login form css */

.loginformcontainer {
    margin: 30% 0% 0% 0%;
    position: relative;
    /* margin-top: 40px; */
}

.first-input {
    position: relative;
    margin: 10% 0%;

}

.first-input i {
    position: absolute;
    /* top: 5px; */
    /* left: 15px; */
    padding: 0% 3% 0% 3%;
    font-size: 18px;

    color: #7F8592;

}

.first-input input,
.first-input textarea {
    width: 100%;
    border: none;
    border-bottom: 2px solid #7F859280;
    padding-left: 50px;
    padding-bottom: 15px;
    font-size: 15px;
    font-weight: 700;
    /* text-transform: uppercase; */
    transition: border .3s;
    background-color: transparent;

}

.first-input input::placeholder {
    color: #7F859280;
    opacity: 1;
    /* border: none; */
}

.first-input input::placeholder,
.first-input textarea::placeholder {
    color: #7F859280;
    /* border: none; */

}

.first-input input:focus,
.first-input textarea:focus {
    border: none;
    /* border-bottom: 2px solid #7F859280; */
    border-color: #2a2a2a;
}

.firstsubmit-input input {
    margin: 10% 20% 5% 20%;

    padding: 15px 50px;
    background-color: #D2D2D2;
    color: rgb(3, 3, 3);
    border: none;
    font-weight: 700;
    transition: background-color .3s;
    width: 295px;
    font-size: 19px;
}

.firstsubmit1-input input {
    margin: 8% 20% 0% 20%;
    padding: 15px 50px;
    background-color: #000000;
    color: #fff;
    border: none;
    font-weight: 700;
    font-size: 19px;
    transition: background-color .3s;
    width: 295px;

}

.firstsubmit-input input:hover {
    background-color: #07395c;
    color: #fff;

}

.firstsubmit1-input input:hover {
    background-color: #07395c;
}

.first-input input:focus-visible {
    border-bottom: 2px solid #7F859280;
    outline: none !important;
}

/* below part*/
.firstconnectWith {
    font-size: 17px;
    margin: 3% 0% 0% 0%;
    color: #7F859280;
    text-align: center;

}

.firstsymbolcont {
    display: flex;
    justify-content: center;
}

.firstsymbol {
    height: 10px;
    margin: 0% 2% 0% 2%;

}
@media (max-width:1601px) {
    .Loginline {
        border-bottom: 6px solid #707070;
        width: 149px;
        margin: auto;
    }
    .Logincss h1 {
        font-size: 45px;
        color: black;
        font-weight: 500;
    }
    .Registercss h1 {
        font-size: 45px;
        color: black;
        font-weight: 400;
    }

    
}
@media (max-width:1296px) {
  
    .Loginline {
        border-bottom: 6px solid #707070;
        width: 110px;
        margin: auto;
    }
    .Logincss h1 {
        font-size: 38px;
        color: black;
        font-weight: 600;
    }
    .Registercss h1 {
        font-size: 38px;
        color: black;
        font-weight: 500;
    }
    .formcontainer {
        padding: 2% 7% 0% 7%;
    }
    .firstsubmit-input input {
        margin: 10% 20% 5% 10%;
        padding: 15px 50px;
        background-color: #D2D2D2;
        color: rgb(3, 3, 3);
        border: none;
        font-weight: 700;
        transition: background-color .3s;
        width: 295px;
        font-size: 19px;
    }
    .firstsubmit1-input input {
        margin: 8% 20% 0% 10%;
        padding: 15px 50px;
        background-color: #000000;
        color: #fff;
        border: none;
        font-weight: 700;
        font-size: 19px;
        transition: background-color .3s;
        width: 295px;
    }

    
}
@media (max-width:936px) {
    .Loginline {
        border-bottom: 6px solid #707070;
        width: 100px;
        margin: auto;
    }
    .Logincss h1 {
        font-size: 32px;
        color: black;
        font-weight: 600;
    }
    .Registercss h1 {
        font-size: 32px;
        color: black;
        font-weight: 500;
    }
    .formcontainer {
        padding: 2% 7% 0% 7%;
    }

    .firstsubmit-input input {
        margin: 10% 20% 5% 10%;
        padding: 15px 20px;
        background-color: #D2D2D2;
        color: rgb(3, 3, 3);
        border: none;
        font-weight: 700;
        transition: background-color .3s;
        width: 225px;
        font-size: 19px;
    }   .firstsubmit1-input input {
        margin: 8% 20% 0% 10%;
        padding: 15px 20px;
        background-color: #000000;
        color: #fff;
        border: none;
        font-weight: 700;
        font-size: 19px;
        transition: background-color .3s;
        width: 225px;
    }
    
}
@media (max-width:768px) {
    .imgstyle {
        height: auto;
    }
    .Logincss h1 {
        font-size: 58px;
        color: black;
        font-weight: 500;
    }
    .Registercss h1 {
        font-size: 58px;
        color: black;
        font-weight: 400;
    }
    .Loginline {
        border-bottom: 6px solid #707070;
        width: 188px;
        margin: auto;
    }
    .firstsubmit-input input {
        margin: 10% 20% 5% 20%;
        padding: 15px 50px;
        background-color: #D2D2D2;
        color: rgb(3, 3, 3);
        border: none;
        font-weight: 700;
        transition: background-color .3s;
        width: 295px;
        font-size: 19px;
    }.firstsubmit1-input input {
        margin: 8% 20% 0% 20%;
        padding: 15px 50px;
        background-color: #000000;
        color: #fff;
        border: none;
        font-weight: 700;
        font-size: 19px;
        transition: background-color .3s;
        width: 295px;
    }
    .loginformcontainer {
        margin: 21% 5% 0% 5%;
        position: relative;
        /* margin-top: 40px; */
    }
}
@media (max-width:576px) {
    .imgstyle {
        height: auto;
    }
    .Loginline {
        border-bottom: 6px solid #707070;
        width: 149px;
        margin: auto;
    }
    .Logincss h1 {
        font-size: 45px;
        color: black;
        font-weight: 500;
    }
    .Registercss h1 {
        font-size: 45px;
        color: black;
        font-weight: 400;
    }

    
}
@media (max-width:437px) {
    .imgstyle {
        height: auto;
    }
    .Loginline {
        border-bottom: 6px solid #707070;
        width: 100px;
        margin: auto;
    }
    .Logincss h1 {
        font-size: 32px;
        color: black;
        font-weight: 600;
    }
    .Registercss h1 {
        font-size: 32px;
        color: black;
        font-weight: 500;
    }
    .formcontainer {
        padding: 2% 7% 0% 7%;
    }

    .firstsubmit-input input {
        margin: 10% 20% 5% 10%;
        padding: 15px 20px;
        background-color: #D2D2D2;
        color: rgb(3, 3, 3);
        border: none;
        font-weight: 700;
        transition: background-color .3s;
        width: 225px;
        font-size: 19px;
    }   .firstsubmit1-input input {
        margin: 8% 20% 0% 10%;
        padding: 15px 20px;
        background-color: #000000;
        color: #fff;
        border: none;
        font-weight: 700;
        font-size: 19px;
        transition: background-color .3s;
        width: 225px;
    }
    
}
