.viewcontainer {
    padding: 2% 10% 0% 11%;
    background-color: #fffef8;
}
.profilecss {
    display: flex;
    justify-content: center;
    margin: 5% 0%;
}
.buttonstyle {
  display: flex;
  justify-content: center;
}

.profilecssh1 {
    border-radius: 50%;
    /* border: 1px solid black; */
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: #f9d31d;
    text-align: center;
    font-size: 75px;
}


.viewformcontainer {
    margin: 7% 18% 0% 18%;
    position: relative;
    /* margin-top: 40px; */
}

.viewlast-input {
    position: relative;
    margin: 2% 0%;

}

.viewlast-input i {
    position: absolute;
    /* top: 5px; */
    /* left: 15px; */
    padding: 0% 0% 0% 0%;
    font-size: 18px;

    color: #ffba00;

}

.viewlast-input input,
.viewlast-input textarea {
    width: 100%;
    border: none;
    border-bottom: 2px solid #7F859280;
    padding-left: 50px;
    padding-bottom: 15px;
    font-size: 15px;
    font-weight: 700;
    /* text-transform: uppercase; */
    transition: border .3s;
    background-color: transparent;

}

.viewlast-input input::placeholder {
    color: #7F859280;
    opacity: 1;
    /* border: none; */
}

.viewlast-input input::placeholder,
.viewlast-input textarea::placeholder {
    color: #7F859280;
    /* border: none; */

}

.viewlast-input input:focus,
.viewlast-input textarea:focus {
    border: none;
    /* border-bottom: 2px solid #7F859280; */
    border-color: #2a2a2a;
}

.viewlastsubmit-input input {
    margin: 6% 20% 10% 20%;
    padding: 15px 50px;
    background-color: #f9d31d;
    color: rgb(255 255 255);
    border: none;
    font-weight: 900;
    transition: background-color .3s;
    width: 295px;
    font-size: 22px;
}

.viewlastsubmit1-input input {
    margin: 8% 20% 0% 20%;
    padding: 15px 50px;
    background-color: #000000;
    color: #fff;
    border: none;
    font-weight: 700;
    font-size: 19px;
    transition: background-color .3s;
    width: 295px;

}

.viewlastsubmit-input input:hover {
    background-color: #07395c;
    color: #fff;

}

.viewlastsubmit1-input input:hover {
    background-color: #07395c;
}
.viewlast-input input:focus-visible {
    border-bottom: 2px solid #7F859280;
	outline: none !important;
}

/* below part*/
/* .connectWith{
    font-size: 17px;
    margin: 3% 0% 0% 0%;
    color: #7F859280;
}
.symbolcont{
    display: flex;
    justify-content: center;
}
.symbol{
    height:10px;
    margin: 0% 2% 0% 2%;

} */

/* for image round */
/* body {
    background: whitesmoke;
    font-family: 'Open Sans', sans-serif;
  } */
  .container {
    max-width: 960px;
    margin: 30px auto;
    padding: 20px;
  }
  /* h1 {
    font-size: 20px;
    text-align: center;
    margin: 20px 0 20px;
  }
  h1 small {
    display: block;
    font-size: 15px;
    padding-top: 8px;
    color: gray;
  } */
  .avatar-upload {
    position: relative;
    max-width: 205px;
    margin: 50px auto;
  }
  .avatar-upload .avatar-edit {
    position: absolute;
    right: 12px;
    z-index: 1;
    top: 10px;
  }
  .avatar-upload .avatar-edit input {
    display: none;
  }
  .avatar-upload .avatar-edit input + label {
    display: inline-block;
    width: 34px;
    height: 34px;
    margin-bottom: 0;
    border-radius: 100%;
    background: #FFFFFF;
    border: 1px solid transparent;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    font-weight: normal;
    transition: all 0.2s ease-in-out;
  }
  .avatar-upload .avatar-edit input + label:hover {
    background: #f1f1f1;
    border-color: #d6d6d6;
  }
  .avatar-upload .avatar-edit input + label:after {
    content: "\f040";
    font-family: 'FontAwesome';
    color: #757575;
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    text-align: center;
    margin: auto;
  }
  .avatar-upload .avatar-preview {
    width: 192px;
    height: 192px;
    position: relative;
    border-radius: 100%;
    border: 6px solid #F8F8F8;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  }
  .avatar-upload .avatar-preview > div {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }