.search-container{
  height: 40%;
  margin: 0px 05px 0px 05px;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  overflow:hidden;
  
}
.search-input{
  margin-top: -0.9px;
  height: 100%;
  font-size: large;
  outline: none;
  overflow-y:hidden;
}
.search-button{
  width: 25px;
  border: none;
  height: 96%;
  display: flex;
  overflow-y:hidden;
  align-items: center;
  justify-content: center;
  background-color: #b6b6b6;
  border-left: 2px solid black;
}