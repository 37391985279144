.Registerstyle {
    margin: 0%;
}

.Registerimgstyle {
    height: 1080px;
}

.Registercontainer {
    padding: 2% 10% 0% 11%;
}

.Loginstylecss {
    font-size: 58px;
    /* font-weight: 400; */

    color: black;
}

.Loginstylecss:hover {
    font-size: 58px;
    color: black;
}

.Loginstylecss h1 {
    font-size: 58px;
    font-weight: 400;

    color: black;
}

.Registerstylecss {
    /* font-size: 43px; */
    color: black;
    /* font-weight: 400; */

}

.Registerstylecss h1 {
    font-size: 58px;
    color: black;
    font-weight: 500;

}

.Registerstylecss:hover {
    font-size: 58px;
    color: black;
}

.Registerstyleline {
    border-bottom: 6px solid #707080;
    width: 239px;
    margin: auto;
}

/* register pages */



.registerformcontainer {
    margin: 15% 0% 0% 0%;
    position: relative;
    /* margin-top: 40px; */
}

.last-input {
    position: relative;
    margin: 10% 0%;

}

.last-input i {
    position: absolute;
    /* top: 5px; */
    /* left: 15px; */
    padding: 0% 3% 0% 3%;
    font-size: 18px;

    color: #252525;

}

.last-input input,
.last-input textarea {
    width: 100%;
    border: none;
    border-bottom: 2px solid #7F859280;
    padding-left: 50px;
    padding-bottom: 15px;
    font-size: 15px;
    font-weight: 700;
    /* text-transform: uppercase; */
    transition: border .3s;
    background-color: transparent;

}

.last-input input::placeholder {
    color: #7F859280;
    opacity: 1;
    /* border: none; */
}

.last-input input::placeholder,
.last-input textarea::placeholder {
    color: #7F859280;
    /* border: none; */

}

.last-input input:focus,
.last-input textarea:focus {
    border: none;
    /* border-bottom: 2px solid #7F859280; */
    border-color: #2a2a2a;
}

.lastsubmit-input input {
    margin: 0% 20% 0% 20%;
    padding: 15px 50px;
    background-color: #D2D2D2;
    color: rgb(3, 3, 3);
    border: none;
    font-weight: 700;
    transition: background-color .3s;
    width: 295px;
    font-size: 19px;
}

.lastsubmit1-input input {
    margin: 8% 20% 0% 20%;
    padding: 15px 50px;
    background-color: #000000;
    color: #fff;
    border: none;
    font-weight: 700;
    font-size: 19px;
    transition: background-color .3s;
    width: 295px;

}

.lastsubmit-input input:hover {
    background-color: #07395c;
    color: #fff;

}

.lastsubmit1-input input:hover {
    background-color: #07395c;
}
.last-input input:focus-visible {
    border-bottom: 2px solid #7F859280;
	outline: none !important;
}

/* below part*/
.connectWith{
    font-size: 17px;
    margin: 3% 0% 0% 0%;
    color: #7F859280;
    text-align: center;

}
.symbolcont{
    display: flex;
    justify-content: center;
}
.symbol{
    height:10px;
    margin: 0% 2% 0% 2%;

}
@media (max-width:1586px) {

    .Loginstylecss h1 {
        font-size: 45px;
        color: black;
        font-weight: 400;
    }
    .Registerstylecss h1 {
        font-size: 45px;
        color: black;
        font-weight: 500;
    }
    .Registerstyleline {
        border-bottom: 6px solid #707080;
        width: 190px;
        margin: auto;
    }
}
@media (max-width:1398px) {
    .Loginstylecss h1 {
        font-size: 38px;
        color: black;
        font-weight: 400;
    }
    .Registerstylecss h1 {
        font-size: 38px;
        color: black;
        font-weight: 500;
    }
    .lastsubmit-input input {
        margin: 0% 20% 0% 20%;
        padding: 15px 20px;
        background-color: #D2D2D2;
        color: rgb(3, 3, 3);
        border: none;
        font-weight: 700;
        transition: background-color .3s;
        width: 225px;
        font-size: 19px;
    }
    .lastsubmit1-input input {
        margin: 8% 20% 0% 20%;
        padding: 15px 20px;
        background-color: #000000;
        color: #fff;
        border: none;
        font-weight: 700;
        font-size: 19px;
        transition: background-color .3s;
        width: 225px;
    }
    .Registerstyleline {
        border-bottom: 6px solid #707080;
        width: 160px;
        margin: auto;
    }
    
}
@media (max-width:1144px) {
    .Registerstyleline {
        border-bottom: 6px solid #707080;
        width: 127px;
        margin: auto;
    }
    .Loginstylecss h1 {
        font-size: 30px;
        color: black;
        font-weight: 500;
    }
    .Registerstylecss h1 {
        font-size: 30px;
        color: black;
        font-weight: 600;
    }
    .lastsubmit-input input {
        margin: 10% 20% 0% 10%;
        padding: 15px 20px;
        background-color: #D2D2D2;
        color: rgb(3, 3, 3);
        border: none;
        font-weight: 700;
        transition: background-color .3s;
        width: 225px;
        font-size: 19px;
    }
    .lastsubmit1-input input {
        margin: 8% 20% 0% 10%;
        padding: 15px 20px;
        background-color: #000000;
        color: #fff;
        border: none;
        font-weight: 700;
        font-size: 19px;
        transition: background-color .3s;
        width: 225px;
    }
}
@media (max-width:767px) {
  
    .Registerimgstyle {
        height: auto;
    }

    .Loginstylecss h1 {
        font-size: 58px;
        color: black;
        font-weight: 400;
    }
    .Registerstylecss h1 {
        font-size: 58px;
        color: black;
        font-weight: 500;
    }
    .Registerstyleline {
        border-bottom: 6px solid #707080;
        width: 227px;
        margin: auto;
    }
    .lastsubmit-input input {
        margin: 10% 20% 0% 20%;
        padding: 15px 20px;
        background-color: #D2D2D2;
        color: rgb(3, 3, 3);
        border: none;
        font-weight: 700;
        transition: background-color .3s;
        width: 295px;
        font-size: 19px;
    }
    .lastsubmit1-input input {
        margin: 8% 20% 0% 20%;
        padding: 15px 50px;
        background-color: #000000;
        color: #fff;
        border: none;
        font-weight: 700;
        font-size: 19px;
        transition: background-color .3s;
        width: 295px;
    }
}@media (max-width:583px) {
  
    .Registerimgstyle {
        height: auto;
    }

    .Loginstylecss h1 {
        font-size: 38px;
        color: black;
        font-weight: 400;
    }
    .Registerstylecss h1 {
        font-size: 38px;
        color: black;
        font-weight: 500;
    }
    .lastsubmit-input input {
        margin: 0% 20% 0% 20%;
        padding: 15px 20px;
        background-color: #D2D2D2;
        color: rgb(3, 3, 3);
        border: none;
        font-weight: 700;
        transition: background-color .3s;
        width: 225px;
        font-size: 19px;
    }
    .lastsubmit1-input input {
        margin: 8% 20% 0% 20%;
        padding: 15px 20px;
        background-color: #000000;
        color: #fff;
        border: none;
        font-weight: 700;
        font-size: 19px;
        transition: background-color .3s;
        width: 225px;
    }
    .Registerstyleline {
        border-bottom: 6px solid #707080;
        width: 160px;
        margin: auto;
    }
}
@media (max-width:419px) {
    .Registerstyleline {
        border-bottom: 6px solid #707080;
        width: 127px;
        margin: auto;
    }
    .Loginstylecss h1 {
        font-size: 28px;
        color: black;
        font-weight: 500;
    }
    .Registerstylecss h1 {
        font-size: 28px;
        color: black;
        font-weight: 600;
    }
    .lastsubmit-input input {
        margin: 10% 20% 0% 10%;
        padding: 15px 20px;
        background-color: #D2D2D2;
        color: rgb(3, 3, 3);
        border: none;
        font-weight: 700;
        transition: background-color .3s;
        width: 218px;
        font-size: 19px;
    }
    .lastsubmit1-input input {
        margin: 8% 20% 0% 10%;
        padding: 15px 20px;
        background-color: #000000;
        color: #fff;
        border: none;
        font-weight: 700;
        font-size: 19px;
        transition: background-color .3s;
        width: 225px;
    }
    
    
}