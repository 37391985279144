/* .apptistcontainer{    
    margin: 0px 100px 0px 100px;
} */
.apptistcontainer {
    width: 100%;
    padding-right: var(--bs-gutter-x, .75rem);
    padding-left: var(--bs-gutter-x, .75rem);
    margin-right: auto;
    margin-left: auto;
}
.forimgstyle {
    position: static;
}
.test-new {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 632px;
    z-index: -1;

}


.label {
    margin: 765px 0px 0px 0px;
}

.label h1 {
    font-size: 66px;
    color: #ffffff;
    /* text-align: center; */
    /* margin-left: 32px; */
    padding: 48px;
    background-color: rgb(0, 0, 0, 0.28);

    font-weight: 600;
}
.bgc-1{
    background-color: #FBC6A4;
    height: 1000px;
}
.bgc-2{
    background-color: #9FE6A0;
    height: 1000px;

}
.bgc-3{
    background-color: #FFC288;
    height: 1000px;

}

.carbut {
    margin-bottom: 15%;
}

.carback {
    background-color: #FFE399;
}

.carbuts {

    margin-bottom: 26%;
    margin-left: -10%;
    text-align: left;
}

.carbuts h5 {
    margin-bottom: 2%;
    font-size: 106px;
    color: black;
    font-weight: bold;
}
.carbuts p {
    margin-bottom: 0%;
    font-weight: 600;
    font-size: 24px;
    color: black;
}
.carbuts button {
    margin-top: 2%;
    font-size: 20px;
    padding: 1.2% 4%;
    font-weight: 500;
    color: white;
}

/* 3 cards css*/

.backgg {
    background-color: #8EE3BB;
}

.backg1 {
    background-color: #FFD0BA;
}

.cardtextss {
    text-align: left;
    /* display: flex; */
    align-items: center;
    margin: 25% 24% 25% 10%;
}

.cardtextss h5 {
    margin-bottom: 2%;
    font-size: 34px;
    color: black;
    font-weight: 500;
}

.cardtextss p {

    margin-bottom: 0%;
    font-weight: 500;
    font-size: 21px;
    color: black;
}

.cardtextss button {
    margin-top: 2%;
    font-size: 20px;
    padding: 1.2% 4%;
    font-weight: 500;
    color: white;
}

/* courses */
/* .seccourses {
    margin: 5% 0% 5% 0%;
} */
.seccourses {
    max-width: 1600px;
    margin: auto;
}

.textcourse h1 {
    font-size: 58px;
    font-weight: 700;
    margin:1%;
}

.textcourse p {
    font-size: 21px;
    font-weight: 400;

}

.viewtext {
    font-size: 21px;
    font-weight: 700;
    margin-right: 2%;

}

.viewtext:hover {
    text-decoration: underline rgb(0, 0, 0);

}

.banner1 {
    margin: 0px 0px 0px 0px;
    height: 815px;
    width: 100%;
    background-image: url("../../Utils/academy-certification-curriculum-school-icon.jpg");
    background-position: top center;
    background-size: cover;
}

/* card2 */
.card2 {
    margin: 100px 10px 10px 10px;
}

.card2text {
    padding-left: 6%;
    padding-right: 5%;
}

.card2text p {
    font-size: 18px;
    margin: 3% 0%;


}

.card2text h1 {
    font-size: 66px;

}

.card2text1 {
    padding-left: 6%;
    padding-right: 5%;
}

.card2text1 p {
    font-size: 18px;
    margin: 3% 0%;
}

.card2text1 h1 {
    font-size: 66px;

}




.carosel2m {
    margin-top: 100px;
}

.cardpara {
    display: flex;
    align-items: center;
    text-align: left;
    margin-top: 100px;

}
.carosel2mh1 {
    font-size: 28px;
    font-weight: 900;
}
.teamstyleh1 {
    font-size: 64px;
}
.teamstylep {
    font-size: 21px;
    margin: 2% 23% 0% 23%;
}

.team {
    margin: 8% auto auto auto;
    background-image: url("../../Utils/person (4).jpg");
    background-position: top center;
    background-size: cover;
    border: 2px solid rgb(255, 255, 255);
    border-radius: 25px;
    max-width: 365px;
}


.teamh {
    margin: 364px 0px 0px 10px;
    color: white;
    font-size: 30px;
    font-weight: bold;
    text-align: left;


}

.teamp {
    margin: 10px;
    font-size: 20px;
    color: white;
    text-align: left;

}

.linelogos {
    display: flex;
    color: white;
    align-items: center;
    margin: 13px 10px 13px 10px;
}

.blog {
    text-align: left;
    background-color: #F0C32F;
    color: white;
    text-align: center;
    padding-top: 70px;

}

.blogh {
    font-size: 65px;
}

.ceo {
    text-align: left;
}

.facebook {
    text-align: left;
}

.twitter {
    margin-left: 20px;
}

.bts {
    border-radius: 50%;
    height: 35px;
    width: 35px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    margin-left: 250px;
}

.sec {
    margin-top: 100px;
}

.line2 {
    display: flex;
    color: white;
    align-items: center;
    margin: 55px 10px 13px 10px;
    position: relative;
}

.test-line {
    height: 1px;
    width: 484px;
    background-color: #747474;

    /* display: block; */
    position: absolute;
    /* z-index: -1; */
    left: -10px;
}

.facebookicon {
    color: black;
    border: 2px solid black;
    padding: 6px;
    margin-right: 19px;
    font-size: 35px;
    background-color: #ffffff;
    z-index: 999;

}

.cardblog {
    text-align: left;
}

.contact {
    margin-top: 100px;
}

.contactcontainer {
    margin: 0% 25% 0% 25%;


}

.contactdiv {
    background-image: url("../../Utils/Group 11040@2x.png");
    background-position: top center;
    background-size: cover;

}

.contacttitle {
    padding-top: 50px;
    text-align: center;
}

.contactpa {
    margin-top: 50px;
    text-align: center;

}

/* @media (min-width: 1200px) {
    .apptistcontainer {
        max-width:1600px;
    }
} */

@media (max-width: 1400px) {

    .apptistcontainer {
        max-width: 1550px;
    }
    .cardtextss {
        text-align: left;
        /* display: flex; */
        align-items: center;
        margin: 9% 24% 14% 10%;
    }
    .test-line {
        height: 1px;
        width: 353px;
        background-color: #747474;
        /* display: block; */
        position: absolute;
        /* z-index: -1; */
        left: -10px;
    }

}

@media (max-width: 1200px) {
    .cardtextss {
        text-align: left;
        /* display: flex; */
        align-items: center;
        margin: 6% 24% 20% 10%;
    }

    .cardtextss h5 {
        margin-bottom: 2%;
        font-size: 22px;
        color: black;
        font-weight: bold;
    }

    .cardtextss p {
        margin-bottom: 0%;
        font-weight: 500;
        font-size: 18px;
        color: black;
    }
    .test-line {
        height: 1px;
        width: 300px;
        background-color: #747474;
        /* display: block; */
        position: absolute;
        /* z-index: -1; */
        left: -10px;
    }


}


@media (max-width: 992px) {
    .apptistcontainer {
        width: 100%;
        padding-right: var(--bs-gutter-x, .75rem);
        padding-left: var(--bs-gutter-x, .75rem);
        margin-right: auto;
        margin-left: auto;
    }

    .test-new {
        position: static;
    }


    .label {
        margin-top: 45%;
        background-color: rgb(0, 0, 0, 0.28);
        width: 34%;
    }

    .label h1 {
        font-size: 30px;
        color: #ffffff;
        /* text-align: center; */
        /* margin-left: 32px; */
        padding: 18px;
        background-color: transparent;
        font-weight: 700;
    }
    .bgc-1 {
        background-color: #FBC6A4;
        height: 600px;
    }
    .bgc-2{
        background-color: #9FE6A0;
        height: 600px;
    
    }
    .bgc-3{
        background-color: #FFC288;
        height: 600px;
    
    }
    

    .carbut {
        margin-bottom: 10%;
    }

    .carback {
        background-color: #FFE399;
    }

    .carbuts {

        margin-bottom: 26%;
        margin-left: -10%;
        text-align: left;
    }

    .carbuts h5 {
        margin-bottom: 2%;
        font-size: 50px;
        color: black;
        font-weight: 700;
    }

    .carbuts p {
        margin-bottom: 0%;
        font-weight: 500;
        font-size: 15px;
        color: black;
    }

    .carbuts button {
        margin-top: 2%;
        font-size: 20px;
        padding: 1.2% 4%;
        font-weight: 500;
        color: white;
    }

    /* 3 cards css*/

    .backgg {
        background-color: #8EE3BB;
    }

    .backg1 {
        background-color: #FFD0BA;
    }

    .cardtextss {
        text-align: left;
        /* display: flex; */
        align-items: center;
        margin: 25% 24% 25% 10%;
    }

    .cardtextss h5 {
        margin-top: -10%;
        font-size: 17px;
        color: black;
        font-weight: 700;
    }

    .cardtextss p {
        margin-top: 4%;
        font-weight: 500;
        font-size: 14px;
        color: black;
        /* margin-bottom: -8%; */
    }

    .cardtextss button {
        margin-top: 5% !important;
        font-size: 15px;
        padding: 1.2% 4%;
        font-weight: 500;
        color: white;
    }

    /* courses */
    .seccourses {
        margin: 5% 0% 5% 0%;
    }

    .textcourse h1 {
        font-size: 45px;
        font-weight: 800;
    }

    .textcourse p {
        font-size: 16px;
        font-weight: 400;

    }

    .viewtext {
        font-size: 21px;
        font-weight: 700;
        margin-right: 2%;

    }

    .viewtext:hover {
        text-decoration: underline rgb(0, 0, 0);

    }

    .banner1 {
        margin: 0px 0px 0px 0px;
        height: 815px;
        width: 100%;
        background-image: url("../../Utils/academy-certification-curriculum-school-icon.jpg");
        background-position: top center;
        background-size: cover;
    }

    /* card2 */
    .card2 {
        margin: 100px 10px 10px 10px;
    }

    .card2text {
        padding-left: 6%;
        padding-right: 5%;
    }

    .card2text p {
        font-size: 18px;
        margin: 3% 0%;


    }

    .card2text h1 {
        font-size: 45px;

    }

    .card2text1 {
        padding-left: 6%;
        padding-right: 5%;
    }

    .card2text1 p {
        font-size: 18px;
        margin: 3% 0%;
    }

    .card2text1 h1 {
        font-size: 45px;

    }




    .carosel2m {
        margin-top: 100px;
    }

    .cardpara {
        display: flex;
        align-items: center;
        text-align: left;
        margin-top: 100px;

    }

    .team {
        margin: auto;
        background-image: url("../../Utils/person (4).jpg");
        background-position: top center;
        background-size: cover;
        border: 2px solid rgb(255, 255, 255);
        border-radius: 25px;
        max-width: 365px;
    }

    .teamh {
        margin: 364px 0px 0px 10px;
        color: white;
        font-size: 30px;
        font-weight: bold;
        text-align: left;


    }

    .teamp {
        margin: 10px;
        font-size: 20px;
        color: white;
        text-align: left;

    }

    .linelogos {
        display: flex;
        color: white;
        align-items: center;
        margin: 13px 10px 13px 10px;
    }

    .blog {
        text-align: left;
        background-color: #F0C32F;
        color: white;
        text-align: center;
        padding-top: 70px;

    }

    .blogh {
        font-size: 44px;
    }

    .ceo {
        text-align: left;
    }

    .facebook {
        text-align: left;
    }

    .twitter {
        margin-left: 20px;
    }

    .bts {
        border-radius: 50%;
        height: 35px;
        width: 35px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        margin-left: 55%;
    }

    .sec {
        margin-top: 100px;
    }

    .line2 {
        display: flex;
        color: white;
        align-items: center;
        margin: 55px 10px 13px 10px;
        position: relative;
    }

    .test-line {
        height: 1px;
        width: 484px;
        background-color: #747474;

        /* display: block; */
        position: absolute;
        /* z-index: -1; */
        left: -10px;
    }

    .facebookicon {
        color: black;
        border: 2px solid black;
        padding: 6px;
        margin-right: 19px;
        font-size: 35px;
        background-color: #ffffff;
        z-index: 999;

    }

    .cardblog {
        text-align: left;
    }

    .contact {
        margin-top: 100px;
    }

    .contactcontainer {
        margin: 0% 17% 0% 17%;
    }

    .contactdiv {
        background-image: url("../../Utils/Group 11040@2x.png");
        background-position: top center;
        background-size: cover;

    }

    .contacttitle {
        padding-top: 50px;
    }

    .contactpa {
        margin-top: 50px;
    }



}

@media (max-width: 768px) {
    .test-new {
        position: static;
        max-width: 100%;
    }

    .label {
        margin-top: 45%;
        background-color: rgb(0, 0, 0, 0.28);
        width: 100%;
    }

    .label {
        margin-top: 112%;
        background-color: rgb(0, 0, 0, 0.28);
        width: 100%;
    }

    .label h1 {
        font-size: 50px;
        color: #ffffff;
        /* text-align: center; */
        /* margin-left: 32px; */
        padding: 43px;
        background-color: transparent;
        font-weight: 700;
    }
    .carbuts h5 {
        margin-bottom: 2%;
        font-size: 50px;
        color: black;
        font-weight: 700;
    }
}

@media (max-width: 576px) {
    .test-line {
        height: 1px;
        width: 345px;
        background-color: #747474;
        /* display: block; */
        position: absolute;
        /* z-index: -1; */
        left: -10px;
    }
    .facebookicon {
        color: black;
        border: 2px solid black;
        padding: 6px;
        margin-right: 5px;
        font-size: 35px;
        background-color: #ffffff;
        z-index: 999;
    }

}



/* form css */