.fs{
    font-size: 22px;
    font-weight: 500;
    padding:30px;
    color: rgb(255, 255, 255);

}
/* .fontnav {
    font-size: 18px;
} */
.bgstyle {
    background-color: transparent;
}
.logo {
    height: 108px;
}

/* .tags:hover{
    text-decoration: underline;
} */
/* .tags::before {
    width: 0%;
    bottom: 10px;
  }
  
  .tags:hover::before {
    width: 100%;
  } */

/* .logo{
    float: left;
} */
.tagsprofile {
    background-color: #6c6c68;
    color: black;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}
@media (max-width: 1400px) {
    .fontnav {
        font-size: 19px;
    }
    
}
@media (max-width: 1200px) {
    .fontnav {
        font-size: 17px;
    }
    
}

@media (max-width: 992px) {
    .fontnav {
        font-size: 15px;
    }
    
}
@media (max-width: 768px) {
    .fontnav {
        font-size: 15px;
    }
    
}
@media (max-width: 540px) {
    .fontnav {
        font-size: 15px;
    }
    
}