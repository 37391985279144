.main-card{
  background-color: black;
  margin: 10px;

}
.card-img-overlay {
  background-color: rgba(#000, 0.8);
}
.head-text{
  position: relative;
  color: white;
}
.center__text{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.secondLead{
  width: 300px;
  height: 350px;
}
.bottom-div{
  /* border: 10px solid hsla(0,0%,100%,.5); */
  background: #000;
  background-image: url('http://localhost:3000/uploads\3f80a0f0-1c04-4fdd-88bb-e528ffecc61fIMG_3645.PNG');
  color: white;
  height: 50%;
  position: relative;
  opacity: 0.8;
  top: 50%;
  padding: 10px;
}
.duration{
  margin-top: 15%;
  display: flex;
  justify-content: space-evenly;
  background-color: white;
  border-radius: 5px;
  color: #000;
  font-weight: 700;

}