.cardb{
    margin: -7px 0px 0px 0px;
    background-color: #FFE399;
    height: 972px;
    display: flex;
    align-items: center;



}
.carda{
    margin:0px;
}
.cardh{
    margin: 0% 0% 3% 6%;
    text-align: left;
    font-size: 104px;
}
.cardp{
    margin: 0% 14% 3% 6%;
    text-align: left;
    font-size: 24px;
    font-weight: 500;
}
.test-newnew {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 640px;
    z-index: -1;
}
.last1{
    display: flex;
    align-items: center;
    justify-content: left;
    margin-left: 6%;

}
.icon12 {

    color: black;
    border: 2px solid #ffffff;
    padding: 6px;
    margin-right: 19px;
    border-radius: 50%;
    font-size: 35px;
    background-color: #ffffff;
    z-index: 999;

}
.contact{
    margin-top:115px;
}
.mapimg{
    width: 543px;
    /* background-image: url("../../Utils/Mask Group 52@2x.png"); */
}
.contactimg{
    width: 543px;
    /* background-image: url("../../Utils/contact-img.png"); */
}

/* 3 cards css*/

.imgcontainer{
    margin: 7% 0% 6% 0%;
}
.backg {
    background-color: #FFD0BA;
}

.backg1 {
    background-color: #FFD0BA;
}

.cardtext {
    text-align: left;
    margin: 25% 24% 0% 10%;
    
}

.cardtext h5 {
    margin-bottom: 2%;
    font-size: 34px;
    color: black;
}

.cardtext p {

    margin-bottom: 0%;
    font-weight: 500;
    font-size: 21px;
    color: black;
}
@media (max-width:992px) {
    .cardh {
        margin: 0% 0% 3% 6%;
        text-align: left;
        font-size: 76px;
    }
    .cardp {
        margin: 0% 14% 3% 6%;
        text-align: left;
        font-size: 22px;
        font-weight: 500;
    }

}
@media (max-width:576px) {
    .cardh {
        margin: 0% 0% 3% 6%;
        text-align: left;
        font-size: 62px;
        font-weight: 800;
    }
    .cardp {
        margin: 0% 14% 3% 6%;
        text-align: left;
        font-size: 20px;
        font-weight: 500;
    }
    
    .test-newnew {
        position: absolute;
        top: 0;
        left: 0;
        max-width: 0;
        z-index: -1;
    }
    
}


