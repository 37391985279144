.Detailapptistcontainer {
    width: 100%;
    padding-right: var(--bs-gutter-x, .75rem);
    padding-left: var(--bs-gutter-x, .75rem);
    margin-right: auto;
    margin-left: auto;
}

.Detailbanner1 {
    background-image: url("../../Utils/Group 11061@2x.png");
    margin: 0px 0px 0px 0px;
    height: 618px;
    width: 100%;
    background-position: top center;
    background-size: cover;
    align-items: center;
    display: flex;
    justify-content: center;
    color: white;
}

.Detailheads {
    display: flex;
    align-items: center;

}

.Detailtexta {
	text-align: left;
	margin-left: 5%;
}

.Detailline {
    border-left: 2px solid black;
    height: 75px;
    margin-left: 62px;

}

.Detaillinecont {
	margin: 5% 5%;
	/* border: 1px solid; */
	padding: 1%;
	box-shadow: 0px 6px 26px -11px #888888;
}
.Detailtexth {
    font-size: 36px;
    font-weight: 500;

}

.Detailtextp {
    font-size: 22px;
    font-weight: 500;

}

.Detailcourse {

    margin: 100px 0px 90px 0px;

}

.Detailcourseh {
    margin: 0px 0px 12px 0px;
    font-size: 58px;
    font-weight: 600;
    text-align: center;
}

.Detailcoursep {
    margin: 0% 10%;
    font-size: 21px;
    font-weight: 500;
}

.DetailLEARN {

    margin: 100px 0px 90px 0px;

}

.DetailLEARNh {
    margin: 0px 0px 12px 0px;
    font-size: 58px;
    font-weight: 600;
    text-align: center;
}

.DetailLEARNp {
    margin: 2% 28%;
    font-size: 21px;
    font-weight: 500;
}

.whatyouget {
    background-image: url("../../Utils/Group 11069@2x.png");
    margin: 0%;
    height: 892px;
    width: 100%;
    background-position: top center;
    background-size: cover;
}

.workingdetail {
    margin: 20% 30% 0% 14%;
}

.workingdetailh1 {
    font-size: 58px;
    margin-bottom: 10%;
}

.leftsidedetail {
    margin: 19% 0% 0% 0%;
}

.leftsidedetail h1 {
    font-size: 58px;
}

.leftsidedetail p {
    text-align: left;
    margin: 8% 0% 0% 30%;
    font-size: 34px;
    font-weight: 500;
}

.DetailLEARNPopular {

    margin: 100px 0px 90px 0px;

}

.DetailLEARNhPopular {
    margin: 0px 0px 12px 0px;
    font-size: 58px;
    font-weight: 600;
}

.DetailLEARNpPopular {
    margin: 2% 28%;
    font-size: 21px;
    font-weight: 500;
}
@media (max-width:576px) {
    .Detailline {
        border-left: 0px;
        height: 75px;
        margin-left: 62px;
    }
    .Detailtexth {
        font-size: 26px;
        font-weight: 600;
    }
    
}