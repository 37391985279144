.side-main{
  /* background-color: black;
  height: 720px;
  width: 335px;
  color: white;
  display: block; */
  height: 100%;
  width: 334px;
  position: fixed;
  margin-top: '96px';
  z-index: 1;
  top: 96;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
}
.randomsvg{
  width: 20%;

}
.firstchild{
  display: flex;
  font-size: 12px;
  padding: 20px;
  font-weight: 100;
}
.side-short{
  width: 84px;
  height: 100%;
  position: fixed;
  z-index: 1;
  top: 96;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  padding-top: 20px;
}