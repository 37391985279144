.nav-bar{
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  position: fixed;
  background-color: black; 
  z-index: 1;
  /* height: 100%; */
  /* width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden; */
}
.hamburger-container{
  width: 84px;
  height: 94px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.logo-container{
  width: 250px;
  height: 94px;
  color: black;
  display: flex;
  align-items: center;
  /* background-color: "white"; */
  justify-content: space-around;
}
.left-child{
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.right-child{
  height: 94px;
  width: 100%;
  display: flex;
  color: white;
  align-items: center;
  justify-content: space-between;
}
.right-left-child{
  height: 100%;
  display: flex;
  justify-content: flex-start;
}

.logo2{
  display: flex;
  color: white;
  display: flex;
  margin: 0px 5px 0px 5px;
  justify-content: space-evenly;
  justify-content: center;
  align-content: stretch;
  flex-wrap: nowrap;
  align-items: center;
}

.search{
  display: flex;
  justify-content: center;
  align-items: center
}
.right-right-child{
  display: flex;
  width: 40%;
  justify-content: space-evenly;
}
.count{
  width: 20px;
  padding: 3px;
  height: 20px;
  color: white;
  font-size: 15px;
  font-weight: 900;
  background: red;
  position: relative;
  padding-left: 5px;
  padding-right: 5px;
  top: -10px;
  left: -10px;
  border-radius: 50%;
}

@media screen and (max-width: 1000px) {
  .nav-bar{
    color: white;
    display: flex;
    align-items: center;
    background-color: black;
  }
  .hamburger-container{
    width: 84px;
    height: 94px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .logo-container{
    width: 250px;
    height: 94px;
    color: black;
    display: flex;
    align-items: center;
    /* background-color: "white"; */
    justify-content: space-around;
  }
  .left-child{
    color: white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .right-child{
    height: 94px;
    width: 100%;
    display: flex;
    color: white;
    align-items: center;
    justify-content: space-between;
  }
  .right-left-child{
    height: 100%;
    display: flex;
    justify-content: flex-start;
  }
  
  .logo2{
    display: flex;
    color: white;
    display: flex;
    margin: 0px 5px 0px 5px;
    justify-content: space-evenly;
    justify-content: center;
    align-content: stretch;
    flex-wrap: nowrap;
    align-items: center;
  }
  
  .search{
    display: flex;
    justify-content: center;
    align-items: center
  }
  .right-right-child{
    display: -webkit-box;
    width: 40%;
    justify-content: space-evenly;
  }
  .count{
    width: 20px;
    padding: 3px;
    height: 20px;
    color: white;
    font-size: 15px;
    font-weight: 900;
    background: red;
    position: relative;
    padding-left: 5px;
    padding-right: 5px;
    top: -10px;
    left: -10px;
    border-radius: 50%;
  }
}