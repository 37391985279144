.main-div{
  margin-top: 70px;
}
.example_2 {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgb(34 35 58 / 20%);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all .3s;
}
.form_header{
  background-color: #ffffff;
  text-align: center;
}