.footb {
    background-image: url("../../Utils/Group 11048.svg");
    background-position: top center;
    background-size: cover;
    overflow: hidden;
}

.last {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
}

.last1 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 465px;
    margin-right: 115px;

}

.icon {
    margin: 0px 10px 0px 10px;
    color: black;
    border-radius: 50%;
    background-color: #ffffff;
    padding: 6%;
    font-size: 25px;
}

.logoapptist {
    /* font-size: 10px; */
    height: 74px;
    /* width: 100; */
}

.links {
    color: white;
    text-decoration: none;
}

.line22323 {
    display: flex;
    color: white;
    align-items: center;
    margin: 25px 10px 13px -15px;
    position: relative;
}

.iconfoot {

    color: black;
    border: 2px solid #ffffff;
    padding: 6px;
    margin-right: 19px;
    border-radius: 50%;
    font-size: 35px;
    background-color: #ffffff;
    z-index: 999;

}

.light {
    text-decoration: none;
}

.column1 {
    margin: 0px;
    padding: 0% 0% 0% 5%;
}

.column2 {
    margin: 0px;
    padding: 0% 0% 0% 10%;
}

.column3 {
    margin: 0px;
    padding: 0% 0% 0% 7%;

}

.column4 {
    margin: 0px;
    padding-left: 5%;

}

.column5 {
    margin: 0px;
    padding-right: 6%;


}

.horizontallineapp1 {
    width: 56%;
    text-align: left;
    margin: -5% 6% 7% 5%;
    color: white;
}

.horizontallineapp2 {
    width: 65%;
    text-align: left;
    margin: 0% 6% 7% 0%;
    color: white;
}

.horizontallineapp3 {
    width: 40%;
    text-align: left;
    margin: 0% 6% 7% 0%;
    color: white;
}

.horizontallineapp4 {
    width: 40%;
    text-align: left;
    margin: 0% 6% 7% 0%;
    color: white;
}

.horizontallineapp5 {
    width: 25%;
    text-align: left;
    margin: 0% 6% 7% 0%;
    color: white;
}

.copyright {
    margin: 0% 15% 0% 34%;
    text-decoration: none;
    color: black;
}

.copyright:hover {
    margin: 0% 15% 0% 40%;
    text-decoration: none;
    color: black;
}

@media (max-width:768px) {
    .footb {
        background-image: none;
        background-position: top center;
        background-size: cover;
        overflow: hidden;
        background-color: black;
    }

    .logoapptist {
        /* font-size: 10px; */
        height: 74px;
        /* width: 100px; */
        display: flex;
    }

    .horizontallineapp1 {
        width: 56%;
        text-align: left;
        margin: 0;
        color: black;
    }

    .column1 {
        margin: 0px;
        padding: 0% 0% 0% 5%;
        text-align: left;
    }

    .column2 {
        margin-top: 1%;
        padding: 0% 0% 0% 10%;
        text-align: left;
    }

    .horizontallineapp2 {
        width: 64%;
        text-align: left;
        margin: 0;
        color: #2d2d2d;
    }

    .column3 {
        margin-left: 3%;
        padding: 0% 0% 0% 7%;
        text-align: left;
    }

    .horizontallineapp3 {
        width: 0;
        text-align: left;
        margin: 0% 0% -3% 0%;
        color: #131313;
    }

    .column4 {
        margin-left: 5%;
        padding-left: 5%;
        text-align: left;
    }

    .horizontallineapp4 {
        width: 0;
        text-align: left;
        margin: 0%;
        color: #1a1a1a;
    }

    .column5 {
        margin-left: 8%;
        padding-right: 6%;
        text-align: left;
    }

    .horizontallineapp5 {
        width: 0;
        text-align: left;
        margin: 0% 0% 0% 0%;
        color: black;
    }

    .copyright {
        margin: 0% 0% 0% 8%;
        text-decoration: none;
        color: black;
        font-size: 13px;
    }
    .last {
        display: block;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;
    }
}