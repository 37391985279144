/* form css */



.single-input {
    position: relative;
    margin-top: 40px;
}

.single-input i {
    position: absolute;
    top: 5px;
    left: 15px;
    color: #de3842;
}

.single-input input,
.single-input textarea {
    width: 100%;
    border: none;
    border-bottom: 2px solid #fff;
    padding-left: 50px;
    padding-bottom: 15px;
    font-size: 15px;
    font-weight: 700;
    /* text-transform: uppercase; */
    transition: border .3s;
    background-color: transparent;

}

.single-input input::placeholder {
    color: rgb(255, 255, 255);
    opacity: 1;
}

.single-input input::placeholder,
.single-input textarea::placeholder {
    color: rgba(250, 253, 255);
}

.single-input input:focus,
.single-input textarea:focus {
    border-color: #df383f;
}

/* .single-input textarea {
	height: 150px;
	min-height: 50px;
} */
.submit-input input {
    margin-top: 80px;
    padding: 15px 50px;
    background-color: #F0C32F;
    color: #fff;
    border: none;
    font-weight: 700;
    transition: background-color .3s;
}

.submit-input input:hover {
    background-color: #07395c;
}

.addressline {
    margin-top: 96px;
    padding-bottom: 50px;


}

.addr {
    display: flex;
    color: white;
    align-items: center;
    margin: 13px 10px 13px 10px;
    justify-content: center;
}

/* .location{

    padding: 18px;
    height: 84px;
    font-size: 60px;
    border: 3px solid white;
    border-radius: 50%;
    width: 84px;
    background-color: #F0C32F;
    margin-right: 200px;
    
}
.phone{
    padding: 18px;
    height: 84px;
    font-size: 60px;
    border: 3px solid white;
    border-radius: 50%;
    width: 84px;
    background-color: #F0C32F;
    margin-right: 200px;
    z-index: 999;


}
.mail{
    padding: 18px;
    height: 84px;
    font-size: 60px;
    border: 3px solid white;
    border-radius: 50%;
    width: 84px;
    background-color: #F0C32F;
}
 */
.phoneimg {
    margin: 0% 22%;
    z-index: 999;
    width: 100px;
}

.locationimg {
    width: 100px;
    z-index: 999;
}

.mailimg {
    width: 100px;
    z-index: 999;
}

.test-dotedline {
    border-top: 5px dotted #1d1a1a;
    height: 4px;
    width: 516px;
    background-color: #ffffff;
    /* display: block; */
    position: absolute;
    /* z-index: -1; */
    /* left: 675px; */
}

.addr1 {
    display: flex;
    color: white;
    font-size: 14px;
    /* align-items: center; */
    margin: 0px 10px 13px 10px;
    justify-content: center;
    text-align: center;
}

.addresstext {

    margin-right: 0px;
}

.Phonestext {
    margin-right: 21.5%;
    margin-left: 20.5%;
}

.emialtext {
    margin: 0px;
}

@media (max-width:768px) {
    .phoneimg {
        margin: 0% 22%;
        z-index: 999;
        width: 60px;
    }

    .locationimg {
        width: 60px;
        z-index: 999;
    }

    .mailimg {
        width: 60px;
        z-index: 999;
    }

    .test-dotedline {
        border-top: 4px dotted #1d1a1a;
        width: 269px;
        background-color: #ffffff;
        position: absolute;

    }

    .addr1 {
        display: flex;
        color: white;
        font-size: 12px;
        align-items: unset;
        margin: 0%;
        text-align: center;

        /* justify-content: center; */
    }

    .addresstext {
        margin-right: 0%;

    }

    .Phonestext {
        margin-right: 13.5%;
        margin-left: 11.5%;
    }

}

@media (max-width:540px) {
    .phoneimg {
        margin: 0% 22%;
        z-index: 999;
        width: 45px;
    }

    .locationimg {
        width: 45px;
        z-index: 999;
    }

    .mailimg {
        width: 45px;
        z-index: 999;
    }

    .test-dotedline {
        border-top: 3px dotted #1d1a1a;
        width: 189px;
        background-color: #ffffff;
        position: absolute;
    }

    .addr1 {
        display: flex;
        color: white;
        font-size: 10px;
        align-items: unset;
        margin: 0%;
        text-align: center;

        /* justify-content: center; */
    }

    .Phonestext {
        margin-right: 19.5%;
        margin-left: 17.5%;
    }
}